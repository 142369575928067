<template>
  <div class="task-list-wrapper">
    <div class="header-box">
      <div class="top">
        <div class="left">点检线路</div>
      </div>
      <div class="bottom f-row">
        <span class="title-No single-row">{{
          taskList && taskList.taskNo
        }}</span>
        <span class="title-Time single-row">总用时：{{
            (taskList && taskList.totalCheckTimes) || '无记录'
          }}</span>
      </div>
    </div>
    <div class="content-box">
      <div class="step-box" @click="handleTaskOperate(item)" v-for="item of linesList" :key="item.checkOrder">
        <div class="step-content-box">
          <div class="left">
            <div class="icon">
              <img :src="icon" alt="icon" />
            </div>
          </div>
          <div class="right">
            <div class="top">
              <div class="title">
                <span> {{ item.deviceName }}</span>
                <span> {{ item.deviceNo }}</span>
              </div>
              <div class="status">
                <span v-if="item.checkFinishStatus">已完成</span>
                <span v-else class="not-bad">未完成</span>
                <i class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"></i>
              </div>
            </div>
            <div class="bottom">
              <span>
                点检项
                <i>{{ item.checkItemNum }}</i>
              </span>
              <span>
                正常
                <i class="good">{{ item.normalNum }}</i>
              </span>
              <span>
                异常
                <i class="error">{{ item.abnormalNum }}</i>
              </span>
            </div>
            <div class="bottom">
              <span>
                用时：
                <i>{{ item.totalTimes && item.totalTimes + 'min' }}</i>
              </span>
            </div>
          </div>
        </div>
        <i v-if="item.checkFinishStatus" class="iconfont iconcheck-active"></i>
        <i v-else class="icon-status"></i>
      </div>
    </div>
    <!-- <div class="tab-bar-box">
      <span class="right" @click="handleSubmitClick">提交</span>
    </div> -->

  </div>
</template>

<script>
import icon from '@/assets/img/check/jiya.png'
import { getApprovalEnterCheckTask } from '@/api/approval'
export default {
  name: 'TaskList',
  components: {},
  data() {
    return {
      icon: icon,
      taskList: null,
      linesList: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getApprovalEnterCheckTask()
    })
  },
  methods: {
    /**
     * 进入点检任务的点检线路接口
     */
    getApprovalEnterCheckTask() {
      const taskId = this.$route.query.taskId
      getApprovalEnterCheckTask(taskId).then((res) => {
        if (res.retCode === 1) {
          this.taskList = res.details.task
          this.linesList = res.details.lines.content
        }
      })
    },
    /**
     * 点检操作
     */
    handleTaskOperate(item) {
      const taskId = this.$route.query.taskId
      this.$router.push({
        path: '/check/operate',
        query: {
          taskId,
          deviceId: item.deviceId,
          checkOrder: item.checkOrder,
          deviceName: item.deviceName,
          operation: 'no',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.task-list-wrapper {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  padding-bottom: 2.5rem;
  background-color: #ffffff;
  .header-box {
    // @include wh(100%, 4rem);
    box-sizing: border-box;
    padding: 0.6rem 2rem;
    background: linear-gradient(
      90deg,
      rgba(79, 147, 255, 1) 0%,
      rgba(58, 187, 253, 1) 100%
    );
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-size: 0.7rem;
        color: white;
      }
      .right {
        display: flex;
        align-items: center;
        i {
          font-size: 0.7rem;
          color: white;
          margin-top: -0.2rem;
        }
        span {
          font-size: 0.7rem;
          color: white;
          margin-left: 0.5rem;
        }
      }
    }
    .bottom {
      font-size: 0.7rem;
      margin-top: 0.6rem;
      .title-No {
        width: 0;
        flex: 1;
        text-align: left;
        padding-right: 1rem;
        color: white;
      }
      .title-Time {
        color: white;
        text-align: right;
      }
    }
  }
  .content-box {
    box-sizing: border-box;
    padding: 1rem;
    .step-box {
      // @include wh(100%, 4rem);
      box-sizing: border-box;
      border-left: 1px solid #f1f1f1;
      position: relative;
      padding: 0 0 0.6rem 1rem;
      &:last-child {
        border: 0;
      }
      .step-content-box {
        @include wh(100%, 100%);
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 0.3rem 0px rgba(51, 51, 51, 0.2);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          @include wh(3rem, 100%);
          @include CC;
          .icon {
            @include wh(1.5rem, 1.5rem);
            img {
              @include wh(100%, 100%);
            }
          }
        }
        .right {
          flex: 1;
          height: 100%;
          box-sizing: border-box;
          padding: 0.5rem 0;
          .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title {
              width: 0;
              flex: 1;
              color: black;
              font-size: 0.75rem;
              margin-right: 0.3rem;
              span {
                display: block;
                margin-bottom: 0.2rem;
              }
              span:nth-child(2) {
                font-size: 0.6rem;
                color: #9a9a9a;
                font-weight: bold;
                margin: 0;
              }
            }
            .status {
              display: flex;
              align-items: center;
              span {
                font-size: 0.6rem;
                color: #00cc00;
                font-weight: bold;
              }
              span.not-bad {
                color: #999999;
                font-size: 0.6rem;
                font-weight: bold;
              }
              i {
                font-size: 0.7rem;
                position: relative;
                margin-left: 0.5rem;
                margin-top: 0.4rem;
              }
            }
          }
          .bottom {
            span {
              font-size: 0.6rem;
              color: #9a9a9a;
              margin-right: 1rem;
              font-weight: bold;
              i {
                margin-left: 0.2rem;
                color: #9a9a9a;
                font-weight: bold;
              }
              i.good {
                color: #00cc00;
              }
              i.error {
                color: #ff3333;
              }
            }
          }
        }
      }
      i.icon-status {
        position: absolute;
        @include wh(0.6rem, 0.6rem);
        left: -0.3rem;
        top: -0.3rem;
        background-color: #cccccc;
        display: block;
        border-radius: 50%;
      }
      i.iconfont {
        color: #3eaa03;
        font-size: 0.7rem;
        left: -0.3rem;
        top: -0.3rem;
        position: absolute;
      }
    }
  }
  .tab-bar-box {
    @include wh(100%, 2.3rem);
    background-color: white;
    position: fixed;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      display: block;
      height: 100%;
      font-size: 0.7rem;
      @include CC;
    }
    span.left {
      width: 7rem;
      color: #666666;
    }
    span.right {
      flex: 1;
      color: white;
      background: linear-gradient(
        90deg,
        rgba(79, 147, 255, 1) 0%,
        rgba(58, 187, 253, 1) 100%
      );
    }
  }
}
</style>
